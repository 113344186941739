import { inject }                          from 'aurelia-framework';
import { BulletinRevisionFilesRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/files-repository';
import { Downloader }                      from 'resources/services/downloader';

@inject(Downloader, BulletinRevisionFilesRepository)
export class BulletinModelFileSubmitted {

    constructor(downloader, bulletinRevisionFilesRepository) {
        this.downloader                      = downloader;
        this.bulletinRevisionFilesRepository = bulletinRevisionFilesRepository;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;
    }

    /**
     * Downloads the file
     *
     * @param file
     */
    downloadFile(file) {
        this.bulletinRevisionFilesRepository.download(file.id)
            .then(result => {
                if (result) {
                    const fileName = file.display_name.split('.').slice(0, -1).join('.');
                    this.downloader.download(result, fileName, file.extension);
                }
            });
    }
}
