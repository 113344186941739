import { DialogController, DialogService } from 'aurelia-dialog';
import { inject }                          from 'aurelia-framework';
import { ReorderFieldsModal }              from 'modules/management/specifications/bulletins/bulletin-revision-fields/reorder-fields-modal';

@inject(DialogService, DialogController, ReorderFieldsModal)
export class BulletinCanBeOrdered {

    constructor(dialogService, dialogController) {
        this.dialogService    = dialogService;
        this.dialogController = dialogController;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {

        this.model = model;
        this.row   = model.row;
    }

    /**
     * Downloads the file
     *
     */
    openModal() {
        return this.dialogService
            .open({ viewModel: ReorderFieldsModal, model: this.row.id })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.dialogController.ok();
                }
            });
    }
}
