import { Bulletin } from 'modules/management/models/bulletin';

export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {Bulletin}
     */
    model() {
        let model = new Bulletin();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.test-designation',
            size:       4,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.norm = {
            type:       'text',
            key:        'norm',
            label:      'form.field.norm',
            size:       4,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.test_code = {
            type:       'text',
            key:        'test_code',
            label:      'form.field.test-code',
            size:       4,
            observers:  [
                (newValue) => {
                    if (!newValue) {
                        this.previousValue = null;
                        return;
                    }

                    if (this.previousValue === undefined) {
                        this.previousValue = newValue;
                    }

                    if (newValue && this.previousValue && newValue.length < this.previousValue.length) {
                        return;
                    }

                    if (newValue && /^\d+\.$/.test(newValue)) {
                        this.previousValue = newValue;
                        viewModel.repository.nextTestCode({ test_code: newValue }).then((response) => {
                            viewModel.model.test_code = response;
                            this.previousValue        = response;
                        });
                    }
                },
            ],
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.observations = {
            type:       'textarea',
            key:        'observations',
            label:      'form.field.observations',
            size:       12,
            required:   false,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        const schema = [
            [this.name, this.norm, this.test_code],
            [this.observations],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                required:   false,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.specifications.bulletins.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
